import React from "react";

function TheOverUnderBook() {
  return (
    <>
      <h1>The Over Under Book</h1>
      <div className="container fluid remove-all-margin">
        <div className="row">
          <div
            className="col-10 remove-all-margin"
            style={{ backgroundColor: "green", height: "80vh" }}
          >
            This is the main image div
          </div>
          <div
            className="col-2 remove-all-margin"
            style={{ backgroundColor: "blue" }}
          >
            This is the object div
          </div>
        </div>
        <div className="row">
          <div
            className="col-12 remove-all-margin"
            style={{ backgroundColor: "yellow", height: "20vh" }}
          >
            This is the sentence div
          </div>
        </div>
      </div>
    </>
  );
}

export default TheOverUnderBook;
