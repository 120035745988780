import React from "react";

function KidsApps() {
  return (
    <>
      <a
        className="App-link"
        href="./studentPicker.zip"
        rel="noopener noreferrer"
      >
        Student wheel picker extension
      </a>
      <br></br>

      <a className="App-link" href="grade-copier.zip" rel="noopener noreferrer">
        Quick student picker extension
      </a>
      <br></br>
      <a className="App-link" href="grade-copier.zip" rel="noopener noreferrer">
        Grade copier extension
      </a>
    </>
  );
}

export default KidsApps;
