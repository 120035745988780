import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Work from "../src/Work";
import Learning from "../src/Learning";
import KidsApps from "../src/KidsApps";
import TheOverUnderBook from "./TheOverUnderBook/TheOverUnderBook";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Work />} />
        <Route path="/work" element={<Work />} />
        <Route path="/kidsapps" element={<KidsApps />} />
        <Route path="/learning" element={<Learning />} />
        <Route path="/theoverunderbook" element={<TheOverUnderBook />} />
      </Routes>
    </>
  );
}

export default App;
