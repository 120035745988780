import React from "react";

function Work() {
  return (
    <>
      <div className="App">
        <div className="App-header">
          <div>some text before the link</div>
          <div>
            <a
              href="http://www.seanmcmillan.dev/learning"
              aria-label="this should not be read"
            >
              A link to Google
            </a>
          </div>
          <div>some text after the link</div>
        </div>
      </div>
    </>
  );
}

export default Work;
