import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";

function MyButton({ count, onClick, className }) {
  return (
    <button className={className} onClick={onClick}>
      Clicked {count} times
    </button>
  );
}

const products = [
  { title: "Cabbage", id: 1 },
  { title: "Garlic", id: 2 },
  { title: "Apple", id: 3 },
];

const listItems = products.map((product) => (
  <li key={product.id}>{product.title}</li>
));

export default function Learning() {
  const [count, setCount] = useState(0);
  function handleClick() {
    setCount(count + 1);
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <MyButton className={"col-12"} count={count} onClick={handleClick} />
          <MyButton className={"col-2"} count={count} onClick={handleClick} />
        </div>
        <div className="row">
          <MyButton className={"col-2"} count={count} onClick={handleClick} />
        </div>
      </div>
      <br></br>
      <ul>{listItems}</ul>
      <motion.div drag>
        <h1 style={{ color: "red" }}>Hello Kaia!</h1>
      </motion.div>
    </>
  );
}
